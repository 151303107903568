import data from "../../dataJson/data.json";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footbar from "../../component/footbar/footbar";

const BokepBarat = () => {
  const filterData = data.filter((item) => item.kategori === "bokep barat");
  const sortedData = [...filterData].reverse(); // Mengambil item dari index terakhir (terbaru)
  const { page } = useParams(); // Ambil nomor halaman dari URL
  const [currentPage, setCurrentPage] = useState(Number(page) || 1); // Halaman saat ini
  const navigate = useNavigate();

  const itemsPerPage = 6; // Jumlah item per halaman
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedData.slice(startIndex, endIndex);

  useEffect(() => {
    // Update URL saat currentPage berubah
    navigate(`/bokep-barat/page/${currentPage}`);
  }, [currentPage, navigate]);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="p-4 h-sm:mb-11 h-smm:mb-28 h-md:mb-72 sm:mb-0">
      <Helmet>
        <title>Koleksi Vidio Bokep Bokep Barat</title>
        <link
          rel="canonical"
          href="https://www.bokepindo.click/bokep-barat/page/1"
        />
        <meta
          name="description"
          content="vidio bokep barat terbaru gangbang dari coytube"
        />
        <meta name="keywords" content="bokep barat bangbang terbaru" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {currentItems.map((item) => (
          <Link
            key={item.id}
            to={`/video/${item.id}/${item.judul.replace(/\s+/g, "-")}`}
          >
            <div className="border border-gray-300 rounded overflow-hidden shadow-lg">
              <img
                src={item.thumbnail}
                alt={item.judul}
                className="w-full h-64 sm:h-60 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg text-pink-500 font-bold">
                  {item.judul}
                </h3>
                <p className="text-pink-500">
                  {item.iframe.length} Video | {item.images.length} Foto
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 space-x-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-black rounded hover:bg-gray-800 disabled:bg-gray-400 text-pink-500 font-bold"
        >
          Previous
        </button>

        <span className="px-4 py-2 text-pink-500 font-bold">
          Page {currentPage} of {totalPages}
        </span>

        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-black rounded hover:bg-gray-800 disabled:bg-gray-400 text-pink-500 font-bold"
        >
          Next
        </button>
      </div>
      <Footbar />
    </div>
  );
};

export default BokepBarat;
