import React from "react";

const Footbar = () => {
  return (
    <div className="bg-black text-pink-500 font-bold py-6 text-center mt-16">
      <h1>
        &copy; {new Date().getFullYear()} Bokep Indo Situs Bokep Terlengkap
      </h1>
    </div>
  );
};

export default Footbar;
