import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageviewIcon from "@mui/icons-material/Pageview";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.length === 0) {
      navigate("/");
    } else {
      navigate(`/result-page?query=${searchTerm}&page=1`);
    }
  };

  return (
    <div className="flex flex-col items-center p-4">
      <div className="w-full max-w-md mb-1 relative">
        <form onSubmit={handleSearch}>
          <input
            type="text"
            placeholder="Cari Video..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full h-[35px] p-2 border text-black border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-pink-500"
          />
          <button
            type="submit"
            // onClick={handleSearch}
            className="absolute right-2 top-[1px] px-1 py-1 bg-pink-500 text-black rounded hover:bg-pink-600"
          >
            <PageviewIcon />
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchBar;
